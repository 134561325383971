import React from "react";
import { createChatBotMessage,   createCustomMessage } from "react-chatbot-kit";

import LearningOptions from "../components/LearningOptions/LearningOptions";
import LinkList from "../components/LinkList/LinkList";
import CustomMessage from "../components/CustomMessage/CustomMessage";
import TextComponent from "../components/TextComponent/TextComponent";
const initialOptions = [
  {
    text: "Identify unique PP&Es",
    id: 1,
    handler: (props) => props.handleNestedOptions(1, [2, 3]),
  },
  {
    text: "List all suppliers",
    id: 4,
    handler: (props) => props.handleNestedOptions(4, [5, 6, 7]),
  },
  {
    text: "Annual revenue 2021-23",
    id: 10,
    handler: (props) => props.handleNestedOptions(10, [11, 12, 13]),
  },
  {
    text: "Type of products 2021-23",
    id: 14,
    handler: (props) => props.handleNestedOptions(14, [15, 16, 17, 18]),
  },
  {
    text: "VAT receivable 2021-23",
    id: 21,
    handler: (props) => props.handleNestedOptions(21, [22, 23, 24]),
  },
  {
    text: "Installment payment obligations",
    id: 25,
    handler: (props) => props.handleNestedOptions(25, [26, 27, 28]),
  },
  {
    text: "Average Accounts Payable by year",
    id: 29,
    handler: (props) => props.handleNestedOptions(29, [30, 31, 32]),
  },
  {
    text: "Cash conversion cycle each year",
    id: 33,
    handler: (props) => props.handleNestedOptions(33, [34, 35, 36, 37]),
  },
  {
    text: "Annual COGS ",
    id: 44,
    handler: (props) => props.handleNestedOptions(44, [45, 46, 47, 48]),
  },
  {
    text: "Annual profit before tax or PBT",
    id: 52,
    handler: (props) => props.handleNestedOptions(52, [53, 54, 55]),
  },
  {
    text: "Average Inventory by year",
    id: 62,
    handler: (props) => props.handleNestedOptions(62, [63]),
  },
];

const frontendOptions = [
  {
    text: "React Basics",
    handler: props => props.actionProvider.handleOptionSelected("React is a popular JS library."),
    id: 3,
  },
  {
    text: "Vue Introduction",
    handler: props => props.actionProvider.handleOptionSelected("Vue is progressive JS framework."),
    id: 4,
  }
];

const backendOptions = [
  {
    text: "Node.js",
    handler: props => props.actionProvider.handleOptionSelected("Node.js is a JS runtime environment."),
    id: 5,
  },
  {
    text: "Django",
    handler: props => props.actionProvider.handleOptionSelected("Django is a high-level Python web framework."),
    id: 6,
  }
];

const optionsData = {
  initialOptions,
  frontendOptions,
  backendOptions
};
const config = {
  botName: "PROFITIZE Bot",
  initialMessages: [
    createChatBotMessage("Hi, I'm here to help. What would you like to ask?", {
      widget: "learningOptions",
    }),
    createCustomMessage('Test', 'custom'),
  ],
  customComponents: {
    // Register your custom component under a unique name
    custom: (props) => <CustomMessage {...props} />,
    textComponent: (props) => <TextComponent {...props} />,
    LearningOptions: (props) => <LearningOptions {...props} />,
    botAvatar: () => <img className = "App-logo" src="logo192.png" alt="logo" />,
  },
  state: {
    optionsData,
    initialOptions
  },
  customStyles: {
    botMessageBox: {
      backgroundColor: "#2898ec",
    },
    chatButton: {
      backgroundColor: "#2898ec",
    },
  },
  widgets: [
    {
      widgetName: "learningOptions",
      widgetFunc: (props) => <LearningOptions {...props} actionProvider={props.actionProvider}/>,
      props: { options: optionsData.initialOptions },
      mapStateToProps: ["optionsData", "initialOptionsData"], 
    },
    {
      widgetName: "textComponent",
      widgetFunc: (props) => <TextComponent {...props} actionProvider={props.actionProvider}/>,
      props: { id: 0 },
    },
    {
      widgetName: "javascriptLinks",
      widgetFunc: (props) => <LinkList {...props} />,
      props: {
        options: [
          {
            text: "Introduction to JS",
            url:
              "https://www.freecodecamp.org/learn/javascript-algorithms-and-data-structures/basic-javascript/",
            id: 1,
          },
          {
            text: "Mozilla JS Guide",
            url:
              "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide",
            id: 2,
          },
          {
            text: "Frontend Masters",
            url: "https://frontendmasters.com",
            id: 3,
          },
        ],
      },
    },
  ],
};

export default config;
