import { createCustomMessage } from "react-chatbot-kit";

const dropdownOptions = [
  {
    text: "Identify unique PP&Es",
    id: 1,
    handler: (props) => props.handleNestedOptions(1, [2, 3]),
  },
  {
    text: "List all PP&E items",
    id: 2,
    handler: (props) => props.handleNestedOptions(2, []),
  },
  {
    text: "Separate PP&Es by category",
    id: 3,
    handler: (props) => props.handleNestedOptions(3, []),
  },
  {
    text: "List all suppliers",
    id: 4,
    handler: (props) => props.handleNestedOptions(4, [5, 6, 7]),
  },
  {
    text: "Find unique suppliers from 2022",
    id: 5,
    handler: (props) => props.handleNestedOptions(5, []),
  },
  {
    text: "List supplier names and amounts excluding VAT",
    id: 6,
    handler: (props) => props.handleNestedOptions(6, []),
  },
  {
    text: "List all customers",
    id: 7,
    handler: (props) => props.handleNestedOptions(7, [8, 9]),
  },
  {
    text: "Identify unique customers from 2022",
    id: 8,
    handler: (props) => props.handleNestedOptions(8, []),
  },
  {
    text: "List customer names",
    id: 9,
    handler: (props) => props.handleNestedOptions(9, []),
  },
  {
    text: "Annual revenue 2021-23",
    id: 10,
    handler: (props) => props.handleNestedOptions(10, [11, 12, 13]),
  },
  {
    text: "Annual gross profit",
    id: 11,
    handler: (props) => props.handleNestedOptions(11, []),
  },
  {
    text: "Annual profit before tax",
    id: 12,
    handler: (props) => props.handleNestedOptions(12, []),
  },
  {
    text: "Annual EBITDA",
    id: 13,
    handler: (props) => props.handleNestedOptions(13, []),
  },
  {
    text: "Type of products 2021-23",
    id: 14,
    handler: (props) => props.handleNestedOptions(14, [15, 16, 17, 18]),
  },
  {
    text: "Table on annual revenue by product 2021-23",
    id: 15,
    handler: (props) => props.handleNestedOptions(15, []),
  },
  {
    text: "Pie chart on annual revenue by product 2021-23",
    id: 16,
    handler: (props) => props.handleNestedOptions(16, []),
  },
  {
    text: "Bar chart on annual revenue by product 2021-23",
    id: 17,
    handler: (props) => props.handleNestedOptions(17, []),
  },
  {
    text: "Type of materials 2021-23",
    id: 18,
    handler: (props) => props.handleNestedOptions(18, [19, 20]),
  },
  {
    text: "Table on annual purchase by direct material 2021-23",
    id: 19,
    handler: (props) => props.handleNestedOptions(19, []),
  },
  {
    text: "Table on annual purchase by indirect material 2021-23",
    id: 20,
    handler: (props) => props.handleNestedOptions(20, []),
  },
  {
    text: "VAT receivable 2021-23",
    id: 21,
    handler: (props) => props.handleNestedOptions(21, [22, 23, 24]),
  },
  {
    text: "VAT payable 2021-23",
    id: 22,
    handler: (props) => props.handleNestedOptions(22, []),
  },
  {
    text: "Net VAT payable 2021-23",
    id: 23,
    handler: (props) => props.handleNestedOptions(23, []),
  },
  {
    text: "Net VAT receivable 2021-23",
    id: 24,
    handler: (props) => props.handleNestedOptions(24, []),
  },
  {
    text: "Installment payment obligations",
    id: 25,
    handler: (props) => props.handleNestedOptions(25, [26, 27, 28]),
  },
  {
    text: "Total installment payments in 2023",
    id: 26,
    handler: (props) => props.handleNestedOptions(26, []),
  },
  {
    text: "Next installment payment amount",
    id: 27,
    handler: (props) => props.handleNestedOptions(27, []),
  },
  {
    text: "Next installment payment due date",
    id: 28,
    handler: (props) => props.handleNestedOptions(28, []),
  },
  {
    text: "Average Accounts Payable by year",
    id: 29,
    handler: (props) => props.handleNestedOptions(29, [30, 31, 32]),
  },
  {
    text: "Days Payable Outstanding (DPO) by year",
    id: 30,
    handler: (props) => props.handleNestedOptions(30, []),
  },
  {
    text: "Show calculations in a chart",
    id: 31,
    handler: (props) => props.handleNestedOptions(31, []),
  },
  {
    text: "Analyze the impact of increased AP days",
    id: 32,
    handler: (props) => props.handleNestedOptions(32, []),
  },
  {
    text: "Cash conversion cycle each year",
    id: 33,
    handler: (props) => props.handleNestedOptions(33, [34, 35, 36, 37]),
  },
  {
    text: "Days inventory outstanding each year",
    id: 34,
    handler: (props) => props.handleNestedOptions(34, []),
  },
  {
    text: "Days sales outstanding each year",
    id: 35,
    handler: (props) => props.handleNestedOptions(35, []),
  },
  {
    text: "Days payable outstanding each year",
    id: 36,
    handler: (props) => props.handleNestedOptions(36, []),
  },
  {
    text: "Analyze the impact of increased AP days",
    id: 37,
    handler: (props) => props.handleNestedOptions(37, [38, 39, 40]),
  },
  {
    text: "Show trends in AP days",
    id: 38,
    handler: (props) => props.handleNestedOptions(38, []),
  },
  {
    text: "Analyze financial impact of increased AP days",
    id: 39,
    handler: (props) => props.handleNestedOptions(39, []),
  },
  {
    text: "Company's business",
    id: 40,
    handler: (props) => props.handleNestedOptions(40, [41, 42, 43]),
  },
  {
    text: "Rank finished goods by revenue during 2021-23 in a pie chart",
    id: 41,
    handler: (props) => props.handleNestedOptions(41, []),
  },
  {
    text: "Rank customers by revenue during 2021-23 in a pie chart",
    id: 42,
    handler: (props) => props.handleNestedOptions(42, []),
  },
  {
    text: "Rank suppliers of materials during 2021-23 in bar chart",
    id: 43,
    handler: (props) => props.handleNestedOptions(43, []),
  },
  {
    text: "Annual COGS ",
    id: 44,
    handler: (props) => props.handleNestedOptions(44, [45, 46, 47, 48]),
  },
  {
    text: "Annual COGS as % of revenue in a bar chart ",
    id: 45,
    handler: (props) => props.handleNestedOptions(45, []),
  },
  {
    text: "Annual gross margin % of revenue in a bar chart ",
    id: 46,
    handler: (props) => props.handleNestedOptions(46, []),
  },
  {
    text: "Monthly COGS as % of revenue in a table",
    id: 47,
    handler: (props) => props.handleNestedOptions(47, []),
  },
  {
    text: "Annual SG&A",
    id: 48,
    handler: (props) => props.handleNestedOptions(48, [49, 50, 51]),
  },
  {
    text: "Annual SG&A as % of revenue in a bar chart ",
    id: 49,
    handler: (props) => props.handleNestedOptions(49, []),
  },
  {
    text: "SG&A breakdown annually by Selling Expenses, General Expenses, and Administrative Expenses in a pie chart\n",
    id: 50,
    handler: (props) => props.handleNestedOptions(50, []),
  },
  {
    text: "SG&A breakdown annually by Selling Expenses, General Expenses, and Administrative Expenses in a bar chart\n",
    id: 51,
    handler: (props) => props.handleNestedOptions(51, []),
  },
  {
    text: "Annual profit before tax or PBT",
    id: 52,
    handler: (props) => props.handleNestedOptions(52, [53, 54, 55]),
  },
  {
    text: "Annual PBT as % of revenue in a table ",
    id: 53,
    handler: (props) => props.handleNestedOptions(53, []),
  },
  {
    text: "Annual PBT as % of revenue in a bar chart",
    id: 54,
    handler: (props) => props.handleNestedOptions(54, []),
  },
  {
    text: "Annual EBITDA",
    id: 55,
    handler: (props) => props.handleNestedOptions(55, [56, 57, 58]),
  },
  {
    text: "Annual PBT as % of revenue in a table ",
    id: 56,
    handler: (props) => props.handleNestedOptions(56, []),
  },
  {
    text: "Annual PBT as % of revenue in a bar chart",
    id: 57,
    handler: (props) => props.handleNestedOptions(57, []),
  },
  {
    text: "List company's PP&E",
    id: 58,
    handler: (props) => props.handleNestedOptions(58, [59, 60, 61]),
  },
  {
    text: "Rank PP&E by purchase price",
    id: 59,
    handler: (props) => props.handleNestedOptions(59, []),
  },
  {
    text: "Table on PP&E purchase price, accumulated depreciation & and net book value in a table",
    id: 60,
    handler: (props) => props.handleNestedOptions(60, []),
  },
  {
    text: "Display in pie chart",
    id: 61,
    handler: (props) => props.handleNestedOptions(61, []),
  },
  {
    text: "Average Inventory by year",
    id: 62,
    handler: (props) => props.handleNestedOptions(62, [63]),
  },
  {
    text: "Days Inventory Outstanding or DIO by year",
    id: 63,
    handler: (props) => props.handleNestedOptions(63, []),
  },
];

const initialOptions = [
  {
    text: "Identify unique PP&Es",
    id: 1,
    handler: (props) => props.handleNestedOptions(1, [2, 3]),
  },
  {
    text: "List all suppliers",
    id: 4,
    handler: (props) => props.handleNestedOptions(4, [5, 6, 7]),
  },
  {
    text: "Annual revenue 2021-23",
    id: 10,
    handler: (props) => props.handleNestedOptions(10, [11, 12, 13]),
  },
  {
    text: "Type of products 2021-23",
    id: 14,
    handler: (props) => props.handleNestedOptions(14, [15, 16, 17, 18]),
  },
  {
    text: "VAT receivable 2021-23",
    id: 21,
    handler: (props) => props.handleNestedOptions(21, [22, 23, 24]),
  },
  {
    text: "Installment payment obligations",
    id: 25,
    handler: (props) => props.handleNestedOptions(25, [26, 27, 28]),
  },
  {
    text: "Average Accounts Payable by year",
    id: 29,
    handler: (props) => props.handleNestedOptions(29, [30, 31, 32]),
  },
  {
    text: "Cash conversion cycle each year",
    id: 33,
    handler: (props) => props.handleNestedOptions(33, [34, 35, 36, 37]),
  },
  {
    text: "Annual COGS ",
    id: 44,
    handler: (props) => props.handleNestedOptions(44, [45, 46, 47, 48]),
  },
  {
    text: "Annual profit before tax or PBT",
    id: 52,
    handler: (props) => props.handleNestedOptions(52, [53, 54, 55]),
  },
  {
    text: "Average Inventory by year",
    id: 62,
    handler: (props) => props.handleNestedOptions(62, [63]),
  },
];

const exitOptions = [
  {
    text: "Check out PROFITIZE",
    url: "https://staging.profitize.ai/",
    id: 5,
  },
  {
    text: "Reset Chat",
    handler: (props) => props.resetChat(),
    id: 6,
  },
];

const optionsData = {
  initialOptions,
};

class ActionProvider {
  constructor(createChatBotMessage, setStateFunc, createClientMessage) {
    this.createChatBotMessage = createChatBotMessage;
    this.createCustomMessage = createCustomMessage;
    this.setState = setStateFunc;
    this.createClientMessage = createClientMessage;
    this.optionsData = optionsData;
    this.dropdownOptions = dropdownOptions;
  }

  // new method
  greet() {
    const greetingMessage = this.createChatBotMessage("Hi, friend.");
    this.updateChatbotState(greetingMessage);
  }
  handleNestedOptions = (id, nextOptionsKeys, action = null) => {
    if (!action) {
      if (!nextOptionsKeys) {
        this.handleExitOptions();
        return;
      }
      console.log("Handling nested options:", nextOptionsKeys); // Debug log
      console.log("dropdown options: ", this.dropdownOptions);
      const nextOptions = this.dropdownOptions.filter((option) =>
        nextOptionsKeys.includes(option.id)
      );
      console.log("Next Options are: ", nextOptions);
      console.log("id is:", id);
      const thisOption = this.dropdownOptions.find(
        (option) => option.id === id
      );
      const message = this.createCustomMessage(thisOption.text, "user", {
        delay: 0,
      });
      this.updateChatbotState(message);
      const message0 = this.createCustomMessage(
        "Sure, give me a second...",
        "bot",
        {
          widget: "textComponent",
          payload: { id, nextOptionsKeys },
          delay: 500,
        }
      );
      this.updateChatbotState(message0);
      if (!nextOptions) {
        this.handleExitOptions();
        return;
      }
    } else {
      console.log("Handling nested options:", nextOptionsKeys); // Debug log
      console.log("dropdown options: ", this.dropdownOptions);
      const nextOptions = this.dropdownOptions.filter((option) =>
        nextOptionsKeys.includes(option.id)
      );
      if (!nextOptions || nextOptionsKeys.length == 0) {
        this.handleExitOptions();
        return;
      }
      const message = this.createCustomMessage(
        "Would you like to ask anything else?",
        "bot",
        {
          widget: "learningOptions",
          payload: nextOptions,
        }
      );
      this.updateChatbotState(message);
    }
  };

  handleExitOptions = () => {
    const message = this.createCustomMessage(
      "That's all we offer in the demo version!",
      "bot",
      {
        widget: "javascriptLinks",
        payload: exitOptions,
      }
    );

    this.updateChatbotState(message);
  };

  handleOptionSelected = (message) => {
    const chatMessage = this.createChatBotMessage(message);
    this.updateChatbotState(chatMessage);
  };

  handleJavascriptList = () => {
    const message = this.createChatBotMessage(
      "Fantastic, I've got the following resources for you on Javascript:",
      {
        widget: "javascriptLinks",
      }
    );

    this.updateChatbotState(message);
  };

  resetChat() {
    this.setState((prevState) => ({
      ...prevState,
      messages: [prevState.messages[0]],
    }));
  }

  updateChatbotState(message) {
    // NOTICE: This function is set in the constructor, and is passed in from the top level Chatbot component. The setState function here actually manipulates the top level state of the Chatbot, so it's important that we make sure that we preserve the previous state.
    console.log("message is:", message);
    this.setState((prevState) => ({
      ...prevState,
      messages: [...prevState.messages, message],
    }));
  }
}

export default ActionProvider;
